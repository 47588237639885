import React from "react";
import Helmet from "react-helmet";
import { graphql, useStaticQuery } from "gatsby";

type Props = {
  description?: string;
  lang?: string;
  meta?: ConcatArray<
    | { name: string; content: any; property?: undefined }
    | { property: string; content: any; name?: undefined }
  >;
  keywords?: string[];
  title?: string;
};

const seoQuery = graphql`
  query SEO {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`;

export const SEO = ({
  description = "",
  lang = "en",
  meta = [],
  keywords = [],
  title = ""
}: Props) => {
  const { site } = useStaticQuery(seoQuery);
  const metaDescription = description || site.siteMetadata.description;
  return (
    <Helmet
      title={title}
      htmlAttributes={{ lang }}
      titleTemplate={`${site.siteMetadata.title} | %s`}
      link={[
        {
          href: "//cloud.typenetwork.com/projects/3807/fontface.css/",
          rel: "stylesheet",
          type: "text/css"
        }
      ]}
      meta={[
        {
          name: `description`,
          content: metaDescription
        },
        {
          property: `og:title`,
          content: title
        },
        {
          property: `og:description`,
          content: metaDescription
        },
        {
          property: `og:type`,
          content: `website`
        },
        {
          name: `twitter:card`,
          content: `summary`
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author
        },
        {
          name: `twitter:title`,
          content: title
        },
        {
          name: `twitter:description`,
          content: metaDescription
        }
      ]
        .concat(
          keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `)
              }
            : []
        )
        .concat(meta)}
    />
  );
};
